@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-quarter {
  animation: spin-quarter 2s linear infinite;
}

.loading div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  animation: spin-quarter 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading div:nth-child(1) {
  animation-delay: 0.1s;
}

.loading div:nth-child(2) {
  animation-delay: 0.2s;
}

.loading div:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes spin-quarter {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* Quarter circle rotation */
  }
}
