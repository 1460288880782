@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./utils/fonts/inter.css');

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@layer base {
  html {
    font-family: 'inter s', Arial, Helvetica, sans-serif;
  }

  body {
    margin: 0;
  }

  h1 {
    @apply text-[17px] font-bold;
  }

  h2 {
    @apply text-[16px] font-bold;
  }

  h3 {
    @apply text-[15px] font-bold;
  }

  h4 {
    @apply text-[14px] font-bold;
  }

  h5 {
    @apply text-[12px] font-bold;
  }

  h6 {
    @apply text-[9px] font-bold;
  }

  p {
    @apply text-[13px];
  }

  /* input {
    @apply outline-none;
  } */
}

@layer components {
}

@layer utilities {
  .container {
    @apply mx-auto max-w-[1200px];
  }

  .level__1:hover .level__1__drop {
    @apply flex;
  }
  .level__2:hover .level__2__drop {
    @apply flex;
  }
  .level__3:hover .level__3__drop {
    @apply flex;
  }
  .level__4:hover .level__4__drop {
    @apply flex;
  }

  .swiper-button-prev,
  .swiper-button-next {
    @apply rounded bg-black/20 p-2 after:text-2xl after:text-white hover:bg-black;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 20px !important;
  }

  .swiper-button-prev-2,
  .swiper-button-next-2 {
    @apply rounded bg-black/20 p-2 after:text-2xl after:text-white hover:bg-black;
  }

  .swiper-button-prev-2::after,
  .swiper-button-next-2::after {
    font-size: 20px !important;
  }

  .tem {
    background: linear-gradient(-45deg, rgba(255, 0, 0, 0) 0 50%, #d0001a 50% 100%);
  }

  .line {
    @apply h-[0.5px] w-full bg-black/10;
  }

  .line-y {
    @apply h-full w-[0.5px] bg-black/10;
  }

  .ant-space-item .ant-select {
    height: 100% !important;
  }

  .capitalize-first::first-letter {
    text-transform: uppercase;
  }

  /* [data-title] {
    @apply relative;
  }

  [data-title]::before {
    @apply invisible absolute left-3/4 top-3/4 whitespace-nowrap rounded border bg-white px-1 py-2 text-xs opacity-0 transition-opacity content-[attr(data-title)] hover:visible hover:opacity-100;
  } */

  .edit_active {
    animation: edit_active forwards 0.4s;
  }

  @keyframes edit_active {
    from {
      left: -100%;
      opacity: 0;
    }
    to {
      left: 100%;
      opacity: 1;
    }
  }

  .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    @apply border-primary bg-primary; /* Màu nền và viền khi được chọn */
  }

  .ant-select-selector:hover,
  .ant-select-selector:focus-within {
    border: 1px solid red !important;
  }

  /* Form Validate */
  .input {
    height: 2.5rem;
    width: 100%;
    border-radius: 0.5rem;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    padding: 0 1rem;
    font-size: 0.875rem;
  }

  .error {
    border-color: red;
    border-width: 1px;
  }

  .normal {
    border-color: rgba(0, 0, 0, 0.2);
  }

  .pagination {
    height: 80px !important;
    width: 80px !important;
    cursor: pointer !important;
    background-color: transparent !important;
  }

  .pagination:hover {
    padding: 4px;
  }

  .pagination img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
